"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OurClientsLogos = void 0;
var OurClientsLogos = [{
  'id': 1,
  'name': 'Total',
  'url': './assets/images/customers/logo_total.png',
  'thumbnail': {
    'url': './assets/images/customers/logo_total-thumbnail.jpeg'
  }
}, {
  'id': 2,
  'name': 'Horus',
  'url': './assets/images/customers/logo-horus.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-horus-thumbnail.jpeg'
  }
}, {
  'id': 3,
  'name': 'Unilever',
  'url': './assets/images/customers/logo_unilever.png',
  'thumbnail': {
    'url': './assets/images/customers/logo_unilever-thumbnail.jpeg'
  }
}, {
  'id': 4,
  'name': 'Total',
  'url': './assets/images/customers/logo-upu.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-upu-thumbnail.jpeg'
  }
}, {
  'id': 5,
  'name': 'La Poste',
  'url': './assets/images/customers/logo-la-poste.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-la-poste-thumbnail.jpeg'
  }
}, {
  'id': 6,
  'name': 'Oneway',
  'url': './assets/images/customers/logo-oneway.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-oneway-thumbnail.jpeg'
  }
}, {
  'id': 7,
  'name': 'Ageroute',
  'url': './assets/images/customers/logo_ageroute.jpg',
  'thumbnail': {
    'url': './assets/images/customers/logo_ageroute-thumbnail.jpeg'
  }
}, {
  'id': 8,
  'name': 'Vizeum',
  'url': './assets/images/customers/logo_vizeum.png',
  'thumbnail': {
    'url': './assets/images/customers/logo_vizeum-thumbnail.jpeg'
  }
}, {
  'id': 9,
  'name': 'Dano',
  'url': './assets/images/customers/logo_dano.png',
  'thumbnail': {
    'url': './assets/images/customers/logo_dano-thumbnail.jpeg'
  }
}, {
  'id': 10,
  'name': 'Assur2000',
  'url': './assets/images/customers/logo-assur2000.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-assur2000-thumbnail.jpeg'
  }
}, {
  'id': 11,
  'name': 'CCBM',
  'url': './assets/images/customers/logo-ccbm.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-ccbm-thumbnail.jpeg'
  }
}, {
  'id': 12,
  'name': 'Dakar Sacré-Coeur',
  'url': './assets/images/customers/logo-dakar-sacre-coeur.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-dakar-sacre-coeur-thumbnail.jpeg'
  }
}, {
  'id': 13,
  'name': 'Teyliom',
  'url': './assets/images/customers/logo-teyliom.jpeg',
  'thumbnail': {
    'url': './assets/images/customers/logo-teyliom-thumbnail.jpeg'
  }
}, {
  'id': 14,
  'name': 'DEM',
  'url': './assets/images/customers/logo-dem.jpg',
  'thumbnail': {
    'url': './assets/images/customers/logo-dem-thumbnail.jpeg'
  }
}, {
  'id': 15,
  'name': 'Ignite',
  'url': './assets/images/customers/logo-ignite.jpeg',
  'thumbnail': {
    'url': './assets/images/customers/logo-ignite-thumbnail.jpeg'
  }
}, {
  'id': 16,
  'name': 'Integral',
  'url': './assets/images/customers/logo-integral.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-integral-thumbnail.jpeg'
  }
}, {
  'id': 17,
  'name': 'Aida Centre',
  'url': './assets/images/customers/logo-aida-centre.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-aida-centre-thumbnail.jpeg'
  }
}, {
  'id': 18,
  'name': 'Logistikom',
  'url': './assets/images/customers/logo-logistikom.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-logistikom-thumbnail.jpeg'
  }
}, {
  'id': 19,
  'name': 'Magenta Design',
  'url': './assets/images/customers/logo-md.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-md-thumbnail.jpeg'
  }
}, {
  'id': 20,
  'name': 'NBA Group',
  'url': './assets/images/customers/logo-nba-group.png',
  'thumbnail': {
    'url': './assets/images/customers/logo-nba-group-thumbnail.jpeg'
  }
}];
exports.OurClientsLogos = OurClientsLogos;